import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  LinearProgress,
  TableContainer,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Check, Delete } from "@material-ui/icons";
import RefreshIcon from "@material-ui/icons/Refresh";
import { makeStyles } from "@material-ui/styles";
import EditIcon from "@mui/icons-material/Edit";
import { Pagination } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { loadUserData } from "../../../../actions/actions";
import { APP_CONFIG } from "../../../../constants/config";
import useAuth from "../../../../hooks/useAuth";
import useDebounce from "../../../../hooks/useDebounce";
import {
  getAutorizarPagamentosEstabelecimento,
  postAutorizarPagamentosEstabelecimento,
} from "../../../../services/beneficiarios";
import { errorMessageHelper } from "../../../../utils/errorMessageHelper";
import px2vw from "../../../../utils/px2vw";

import CustomButton from "../../../../components/CustomButton/CustomButton";
import CustomCollapseTable from "../../../../components/CustomCollapseTable/CustomCollapseTable";
import { MenuOptionsTable } from "../../components/MenuOptionsTable";
import { ModalManager } from "../../components/ModalManager";
import TableHeaderButton from "../../components/TableHeaderButton";

moment.locale();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "25px",
    width: px2vw("100%"),
    "@media (max-width: 1440px)": {
      width: "950px",
    },
    "@media (max-width: 1280px)": {
      width: "850px",
    },
  },
  tableContainer: { marginTop: "1px" },
  pageTitle: {
    color: APP_CONFIG.mainCollors.primary,
    fontFamily: "Montserrat-SemiBold",
  },
}));

const itemColumns = [
  {
    headerText: "Nome",
    key: "conta.razao_social",
    CustomValue: (nome) => (
      <Typography style={{ lineBreak: "loose" }}>{nome}</Typography>
    ),
  },
  {
    headerText: "Email",
    key: "conta.email",
    CustomValue: (email) => (
      <Typography style={{ lineBreak: "anywhere" }}>{email}</Typography>
    ),
  },
  {
    headerText: "CNPJ",
    key: "conta.cnpj",
    CustomValue: (documento) => (
      <Typography style={{ lineBreak: "anywhere" }}>{documento}</Typography>
    ),
  },
  {
    headerText: "Contato",
    key: "conta.celular",
    CustomValue: (celular) => (
      <Typography style={{ lineBreak: "anywhere" }}>
        {celular !== null ? celular : "*"}
      </Typography>
    ),
  },
  {
    headerText: "Tipo Pagamento",
    key: "conta.documento",
    CustomValue: (tipo_pagamento) => (
      <Typography style={{ lineBreak: "loose" }}>{"Benefício"}</Typography>
    ),
  },
  {
    headerText: "Valor",
    key: "valor_pagamento",
    CustomValue: (valor) => (
      <Typography style={{ lineBreak: "loose" }}>
        R$
        {parseFloat(valor).toLocaleString("pt-br", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    headerText: "Status Transação",
    key: "status",
    CustomValue: (status) => (
      <Typography style={{ lineBreak: "loose" }}>{status}</Typography>
    ),
  },
  {
    headerText: "Quantidade",
    key: "reembolso_request.qtd",
    CustomValue: (v) => (
      <Typography style={{ textAlign: "center" }}>{v}</Typography>
    ),
  },
  {
    headerText: "",
    key: "menuCollapse",
  },
];

export default function ListaFolhaDePagamentoAutorizar() {
  const id = useParams()?.id ?? "";
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const token = useAuth();
  const [loading, setLoading] = useState(false);
  const [listaContas, setListaContas] = useState([]);
  const [registros, setRegistros] = useState([]);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    created_at: "",
    data_pagamento: "",
    descricao: "",
  });
  const debouncedFilter = useDebounce(filter, 800);
  const [aprovarTodos, setAprovarTodos] = useState(0);
  const [showAprovarModal, setShowAprovarModal] = useState(false);

  useEffect(() => {
    dispatch(loadUserData(token));
  }, [token, dispatch]);

  const resetFilters = () => {
    setFilter({
      created_at: "",
      data_pagamento: "",
      descricao: "",
    });
  };

  const filters = `created_at=${filter.created_at}&data_pagamento=${filter.data_pagamento}&descricao=${filter.descricao}`;

  const getData = async (token, page = 1) => {
    setLoading(true);
    try {
      const { data } = await getAutorizarPagamentosEstabelecimento(
        token,
        id,
        page,
        "",
        filters
      );
      setListaContas(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(token, page);
  }, [token, page, debouncedFilter]);

  const handleAprovarPagamento = async (dataToken) => {
    setLoading(true);
    try {
      await postAutorizarPagamentosEstabelecimento(
        token,
        id,
        dataToken,
        registros,
        aprovarTodos
      );

      toast.success("Pagamentos aprovados");
      setRegistros([]);
      await getData(token, 1, "");
    } catch (err) {
      console.log(err);
      toast.error(errorMessageHelper(err));
    } finally {
      setShowAprovarModal(false);
      setLoading(false);
    }
  };

  const columns = [
    {
      headerText: "",
      key: "id",
      CustomValue: (id) => {
        return (
          <>
            <Box>
              <Checkbox
                color="primary"
                checked={registros.includes(id)}
                onChange={() => {
                  if (registros.includes(id)) {
                    setRegistros(registros.filter((item) => item !== id));
                  } else {
                    setRegistros([...registros, id]);
                  }
                }}
              />
            </Box>
          </>
        );
      },
    },
    {
      headerText: "DATA",
      key: "created_at",
      CustomValue: (created_at) => {
        return <>{moment.utc(created_at).format("DD MMMM YYYY")}</>;
      },
    },
    { headerText: "DESCRIÇÃO", key: "descricao" },
    { headerText: "STATUS", key: "status_aprovado" },
    {
      headerText: "DATA DE PAGAMENTO",
      key: "data_pagamento",
      CustomValue: (data_pagamento) => {
        return <>{moment.utc(data_pagamento).format("DD MMMM YYYY")}</>;
      },
    },
    // { headerText: "", key: "menu" },
  ];

  const Editar = (row) => {
    const handleEditarFolha = () => {
      const path = generatePath("cadastrar-folha-de-pagamento/:id", {
        id: row.row.id,
      });
      history.push(path);
    };

    return (
      <Box>
        <Box style={{ display: "flex" }}>
          <Box onClick={() => handleEditarFolha()}>
            <EditIcon
              style={{
                fontSize: "25px",
                color: APP_CONFIG.mainCollors.primary,
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.headerContainer}>
        <Box
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className={classes.pageTitle}>
            Aprovar pagamentos - Estabelecimentos
          </Typography>

          <Box style={{ alignSelf: "flex-end" }}>
            <IconButton
              style={{
                backgroundColor: APP_CONFIG.mainCollors.backgrounds,
                color: APP_CONFIG.mainCollors.primary,
              }}
              onClick={() => window.location.reload(false)}
            >
              <RefreshIcon />
            </IconButton>
          </Box>
        </Box>

        <Box
          style={{
            width: "100%",
            backgroundColor: APP_CONFIG.mainCollors.backgrounds,
            borderTopLeftRadius: 27,
            borderTopRightRadius: 27,
          }}
        >
          <Box style={{ margin: 30 }}>
            <Grid
              container
              spacing={3}
              style={{ alignItems: "center", marginBottom: "8px" }}
            >
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Pesquisar por data"
                  size="small"
                  variant="outlined"
                  style={{
                    marginRight: "10px",
                  }}
                  InputLabelProps={{
                    color: APP_CONFIG.mainCollors.secondary,
                    shrink: true,
                    pattern: "d {4}- d {2}- d {2} ",
                  }}
                  type="date"
                  value={filter.created_at}
                  onChange={(e) => {
                    setPage(1);
                    setFilter((prev) => ({
                      ...prev,
                      created_at: e.target.value,
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Pesquisar por data de pagamento"
                  size="small"
                  variant="outlined"
                  style={{
                    marginRight: "10px",
                  }}
                  InputLabelProps={{
                    color: APP_CONFIG.mainCollors.secondary,
                    shrink: true,
                    pattern: "d {4}- d {2}- d {2} ",
                  }}
                  type="date"
                  value={filter.data_pagamento}
                  onChange={(e) => {
                    setPage(1);
                    setFilter((prev) => ({
                      ...prev,
                      data_pagamento: e.target.value,
                    }));
                  }}
                />
              </Grid>

              <TableHeaderButton
                text="Aprovar"
                onClick={() => {
                  setAprovarTodos(false);
                  setShowAprovarModal(true);
                }}
                Icon={Check}
              />

              <TableHeaderButton
                text="Aprovar todos"
                onClick={() => {
                  setAprovarTodos(true);
                  setShowAprovarModal(true);
                }}
              />
            </Grid>

            <Grid
              container
              spacing={3}
              style={{ alignItems: "center", marginBottom: "8px" }}
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  placeholder="Pesquisar por descrição"
                  size="small"
                  variant="outlined"
                  style={{
                    marginRight: "10px",
                  }}
                  value={filter.descricao}
                  onChange={(e) => {
                    setPage(1);
                    setFilter((prev) => ({
                      ...prev,
                      descricao: e.target.value,
                    }));
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <CustomButton color="red" onClick={resetFilters}>
                  <Box display="flex" alignItems="center">
                    <Delete />
                    Limpar
                  </Box>
                </CustomButton>
              </Box>
            </Grid>
          </Box>

          <Typography
            className={classes.pageTitle}
            style={{
              marginLeft: "30px",
              paddingBottom: "16px",
              marginBottom: "1px",
            }}
          >
            CONTAS RECENTES
          </Typography>
        </Box>

        <Box className={classes.tableContainer}>
          {!loading && listaContas?.data && listaContas?.per_page ? (
            <Box minWidth={!matches ? "800px" : null}>
              <TableContainer style={{ overflowX: "auto" }}>
                <CustomCollapseTable
                  columns={columns ? columns : null}
                  itemColumns={itemColumns}
                  data={listaContas.data}
                  Editar={Editar}
                  EditarCollapse={({ row }) => (
                    <MenuOptionsTable
                      row={row?.reembolso_request?.demonstrativo}
                      infoTableColumns={[
                        {
                          headerText: "ID",
                          key: "idTransacao",
                        },
                        {
                          headerText: "NSU",
                          key: "nsu",
                        },
                        {
                          headerText: "Data",
                          key: "dtTransacao",
                        },
                        {
                          headerText: "Total",
                          key: "vlTransacao",
                          CustomValue: (valor) => (
                            <Typography style={{ marginLeft: "6px" }}>
                              R${" "}
                              {parseFloat(valor).toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Typography>
                          ),
                        },
                        {
                          headerText: "Taxa",
                          key: "vlTaxa",
                          CustomValue: (valor) => (
                            <Typography style={{ marginLeft: "6px" }}>
                              R${" "}
                              {parseFloat(valor).toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Typography>
                          ),
                        },
                        {
                          headerText: "Interconexão",
                          key: "vlInterconexao",
                          CustomValue: (valor) => (
                            <Typography style={{ marginLeft: "6px" }}>
                              R${" "}
                              {parseFloat(valor).toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Typography>
                          ),
                        },
                        {
                          headerText: "Líquido",
                          key: "vlLiquido",
                          CustomValue: (valor) => (
                            <Typography style={{ marginLeft: "6px" }}>
                              R${" "}
                              {parseFloat(valor).toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Typography>
                          ),
                        },
                      ]}
                    />
                  )}
                />
              </TableContainer>
            </Box>
          ) : (
            <Box>
              <LinearProgress color="secondary" />
            </Box>
          )}

          <Box
            display="flex"
            alignSelf="flex-end"
            marginTop="8px"
            justifyContent="space-between"
          >
            <Pagination
              variant="outlined"
              color="secondary"
              size="large"
              count={listaContas?.last_page}
              onChange={(e, value) => setPage(value)}
              page={page}
            />
          </Box>
        </Box>
      </Box>

      <ModalManager.SenhaAprovar
        aprovarTodos={aprovarTodos}
        show={showAprovarModal}
        setShow={setShowAprovarModal}
        handleAprovarPagamento={handleAprovarPagamento}
      />
    </Box>
  );
}
