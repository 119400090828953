import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import {
  CompareArrows,
  Delete,
  Edit,
  Lock,
  LockOpen,
  Print,
  ReplayOutlined,
  Search,
} from "@material-ui/icons";
import { DataObject } from "@mui/icons-material";
import { Pagination } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

import { APP_CONFIG } from "../../../../constants/config";
import useAuth from "../../../../hooks/useAuth";
import {
  getTransacaoBeneficiario,
  patchPagamentosContratoAluguelStatusToCreated,
  patchPagamentosVoucherStatusToCreated,
  postBlockCard,
  postUnblockCard,
} from "../../../../services/beneficiarios";

import CustomCollapseTable from "../../../../components/CustomCollapseTable/CustomCollapseTable";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import LoadingScreen from "../../../../components/LoadingScreen/LoadingScreen";
import { ModalManager } from "../ModalManager";

export function MenuOptionsTable({
  row,
  getData,
  printType,
  deleteCallback,
  editType,
  transactionsType,
  infoTableColumns,
  JSONResponse,
  patchStatus,
  blockUnblockCard,
}) {
  const [showDeletarModal, setShowDeletarModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showTransactionsModal, setShowTransactionsModal] = useState(false);
  const [showInfoTableModal, setShowInfoTableModal] = useState(false);
  const [showJSONResponseModal, setShowJSONResponseModal] = useState(false);
  const [showPatchStatusModal, setShowPatchStatusModal] = useState(false);
  const [showBlockUnblockCardModal, setShowBlockUnblockCardModal] =
    useState(false);

  return (
    <Box style={{ display: "flex", flexDirection: "row" }}>
      {printType ? <PrintButton row={row} type={printType} /> : null}

      {deleteCallback ? (
        <>
          <Delete
            style={{ color: "#ED757D", fontSize: "28px" }}
            onClick={() => setShowDeletarModal(true)}
          />

          <ConfirmarDeletarModal
            show={showDeletarModal}
            setShow={setShowDeletarModal}
            row={row}
            getData={getData}
            callback={deleteCallback}
          />
        </>
      ) : null}

      {editType ? (
        <>
          <Edit
            style={{ color: APP_CONFIG.mainCollors.primary, fontSize: "28px" }}
            onClick={() => setShowEditModal(true)}
          />

          <ModalManager.NovoCadastro
            show={showEditModal}
            setShow={setShowEditModal}
            data={row}
            getData={getData}
            tipo={editType}
            update={true}
          />
        </>
      ) : null}

      {transactionsType ? (
        <>
          <CompareArrows
            style={{ color: "#202020", fontSize: "28px" }}
            onClick={() => setShowTransactionsModal(true)}
          />

          <ExtratoBeneficiarioModal
            show={showTransactionsModal}
            setShow={() => setShowTransactionsModal(false)}
            data={row}
          />
        </>
      ) : null}

      {infoTableColumns ? (
        <>
          <Search
            style={{ color: "#202020", fontSize: "28px" }}
            onClick={() => setShowInfoTableModal(true)}
          />

          <InfoTableModal
            show={showInfoTableModal}
            setShow={() => setShowInfoTableModal(false)}
            data={row}
            columns={infoTableColumns}
          />
        </>
      ) : null}

      {JSONResponse ? (
        <>
          <DataObject
            style={{ color: "#202020", fontSize: "28px" }}
            onClick={() => setShowJSONResponseModal(true)}
          />

          <JSONResponseModal
            show={showJSONResponseModal}
            setShow={() => setShowJSONResponseModal(false)}
            data={row}
            json={JSONResponse}
          />
        </>
      ) : null}

      {patchStatus ? (
        <>
          <ReplayOutlined
            style={{ color: "#202020", fontSize: "28px" }}
            onClick={() => setShowPatchStatusModal(true)}
          />

          <PatchStatusModal
            show={showPatchStatusModal}
            setShow={() => setShowPatchStatusModal(false)}
            data={row}
            getData={getData}
            type={patchStatus}
          />
        </>
      ) : null}

      {blockUnblockCard ? (
        <>
          {row?.is_blocked ? (
            <LockOpen
              style={{ color: "#202020", fontSize: "28px" }}
              onClick={() => setShowBlockUnblockCardModal(true)}
            />
          ) : (
            <Lock
              style={{ color: "#202020", fontSize: "28px" }}
              onClick={() => setShowBlockUnblockCardModal(true)}
            />
          )}

          <BlockUnblockCardModal
            show={showBlockUnblockCardModal}
            setShow={() => setShowBlockUnblockCardModal(false)}
            data={row}
            getData={getData}
          />
        </>
      ) : null}
    </Box>
  );
}

const ConfirmarDeletarModal = ({
  show = false,
  setShow = () => false,
  row = {},
  callback = () => null,
  getData = () => null,
}) => {
  const token = useAuth();
  const [loading, setLoading] = useState("");

  const handleClose = () => {
    setShow(false);
  };

  const handleDeletarBeneficiario = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await callback(token, row?.id);
      await getData(token);
    } catch (err) {
      console.log(err);
      toast.error(
        "Ocorreu um erro, não possivel deletar o item. Tente novamente."
      );
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <LoadingScreen isLoading={loading} />
      <DialogTitle id="form-dialog-title">Excluir</DialogTitle>
      <form onSubmit={handleDeletarBeneficiario}>
        <DialogContent style={{ overflow: "hidden" }}>
          <DialogContentText>
            Você gostaria de excluir o item:
          </DialogContentText>

          <DialogContentText>
            {row?.nome_beneficio ? (
              <>
                {row?.nome_beneficio} <br />
              </>
            ) : null}
            {row?.nome_prefeitura ? (
              <>
                {row?.nome_prefeitura} - {row?.sigla} <br />
              </>
            ) : null}
            {row?.nome ? (
              <>
                {row?.nome} <br />
              </>
            ) : null}
            {row?.user?.nome ? (
              <>
                {row?.user?.nome} <br />
              </>
            ) : null}
            {row?.documento ? (
              <>
                {row?.documento} <br />
              </>
            ) : null}
            {row?.user?.documento ? (
              <>
                {row?.user?.documento} <br />
              </>
            ) : null}
            {row?.email ? (
              <>
                {row?.email} <br />
              </>
            ) : null}
            {row?.chave_pix ? (
              <>
                Chave Pix: {row?.chave_pix} <br />
              </>
            ) : null}
            {row?.descricao ? (
              <>
                {row?.descricao} <br />
              </>
            ) : null}
          </DialogContentText>

          <DialogContentText>Essa ação é irreversível.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button color="primary" type="submit">
            Excluir
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const ExtratoBeneficiarioModal = ({
  show = false,
  setShow = () => false,
  data = {},
}) => {
  const token = useAuth();
  const [dataBeneficiario, setDataBeneficiario] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState("");

  const handleClose = () => {
    setShow(false);
  };

  const handleLoadExtrato = async (page = 1) => {
    setLoading(true);
    try {
      const res = await getTransacaoBeneficiario(token, data?.id, page, "");
      setDataBeneficiario(res.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (show) {
      handleLoadExtrato(page);
    }
  }, [token, show, page]);

  const columns = [
    {
      headerText: "Data da Transação",
      key: "data",
      CustomValue: (data) => {
        return (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography style={{ marginLeft: "6px" }}>
              {moment.utc(data).format("DD MMMM")}
            </Typography>
          </Box>
        );
      },
    },
    // {
    //   headerText: "Beneficiário",
    //   key: "extratoable.nome",
    //   CustomValue: (nome) => {
    //     return (
    //       <Box
    //         style={{
    //           display: "flex",
    //           flexDirection: "row",
    //           alignItems: "center",
    //           justifyContent: "center",
    //         }}
    //       >
    //         <Typography style={{ marginLeft: "6px" }}>{nome}</Typography>
    //       </Box>
    //     );
    //   },
    // },
    {
      headerText: "Saldo do dia",
      key: "valor",
      CustomValue: (valor) => (
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FontAwesomeIcon icon={faWallet} style={{ fontSize: "17px" }} />
          <Typography style={{ marginLeft: "6px" }}>
            R${" "}
            {parseFloat(valor).toLocaleString("pt-br", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Box>
      ),
    },
  ];

  const itemColumns = [
    {
      headerText: "Descrição",
      key: "nmDescricao",
    },
    {
      headerText: "Transação Id",
      key: "idTransacao",
    },
    {
      headerText: "Data e hora",
      key: "dtTransacao",
    },
    {
      headerText: "NSU",
      key: "nsu",
    },
    {
      headerText: "Valor Transação",
      key: "vlTransacao",
      CustomValue: (vlTransacao) => {
        return (
          <Box style={{ display: "flex" }}>
            <Typography
              variant=""
              style={{
                fontSize: 17,
                fontWeight: 600,
                color: vlTransacao < 0 ? "red" : "green",
                marginLeft: "6px",
              }}
            >
              R${" "}
              {parseFloat(vlTransacao || 0).toLocaleString("pt-br", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Box>
        );
      },
    },
    {
      headerText: "Valor Saldo",
      key: "vlSaldo",
      CustomValue: (vlSaldo) => {
        return (
          <Box style={{ display: "flex" }}>
            <Typography
              variant=""
              style={{
                fontSize: 17,
                fontWeight: 600,
                color: vlSaldo < 0 ? "red" : "green",
                marginLeft: "6px",
              }}
            >
              R${" "}
              {parseFloat(vlSaldo || 0).toLocaleString("pt-br", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"lg"}
      minWidth={"lg"}
      width={"lg"}
      scroll={"paper"}
    >
      <LoadingScreen isLoading={loading} />

      <DialogContent style={{ paddingBottom: 40, minWidth: "60%" }}>
        <Grid container spacing={4}>
          <Box>
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  backgroundColor: APP_CONFIG.mainCollors.backgrounds,
                  borderRadius: "17px",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    backgroundColor: APP_CONFIG.mainCollors.backgrounds,
                    borderTopLeftRadius: 27,
                    borderTopRightRadius: 27,
                  }}
                ></Box>

                {dataBeneficiario?.per_page ? (
                  <>
                    <Box>
                      <CustomCollapseTable
                        itemColumns={itemColumns}
                        data={dataBeneficiario?.data}
                        columns={columns}
                      />
                    </Box>

                    <Box alignSelf="start" marginTop="8px">
                      {
                        <Pagination
                          variant="outlined"
                          color="secondary"
                          size="large"
                          count={dataBeneficiario.last_page}
                          onChange={(e, value) => setPage(value)}
                          page={page}
                        />
                      }
                    </Box>
                  </>
                ) : (
                  <LinearProgress />
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const InfoTableModal = ({
  show = false,
  setShow = () => false,
  data = [],
  columns = [],
}) => {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"lg"}
      minWidth={"lg"}
      width={"lg"}
      scroll={"paper"}
    >
      <DialogContent style={{ paddingBottom: 40, minWidth: "60%" }}>
        <CustomTable data={data} columns={columns} />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Voltar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const JSONResponseModal = ({
  show = false,
  setShow = () => false,
  data = {},
  json,
}) => {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"lg"}
      minWidth={"lg"}
      width={"lg"}
      scroll={"paper"}
    >
      <DialogContent style={{ paddingBottom: 40, minWidth: "60%" }}>
        <DialogTitle>JSON Response</DialogTitle>

        <DialogContentText style={{ whiteSpace: "pre" }}>
          {JSON.stringify(json, null, 4)}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Voltar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PrintButton = ({ row, type }) => {
  const redirectPrintFolha = () => {
    const path = generatePath(`/dashboard/print/:id??type=${type}`, {
      id: row?.id,
    });

    const newWindow = window.open(path, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Print
      onClick={redirectPrintFolha}
      style={{ color: APP_CONFIG.mainCollors.primary }}
    />
  );
};

const PatchStatusModal = ({
  show = false,
  setShow = () => false,
  data = {},
  getData = () => null,
  type,
}) => {
  const token = useAuth();
  const [loading, setLoading] = useState("");

  const handleClose = () => {
    setShow(false);
  };

  const handleResetStatus = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (type === "voucher")
        await patchPagamentosVoucherStatusToCreated(token, data?.id);
      if (type === "contrato")
        await patchPagamentosContratoAluguelStatusToCreated(token, data?.id);

      toast.success(
        "Status alterado com sucesso."
      );
      await getData(token);
    } catch (err) {
      console.log(err);
      toast.error(
        "Ocorreu um erro, não possivel alterar o status do item. Tente novamente."
      );
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <LoadingScreen isLoading={loading} />
      <DialogTitle id="form-dialog-title">Reverter status</DialogTitle>
      <form onSubmit={handleResetStatus}>
        <DialogContent style={{ overflow: "hidden" }}>
          <DialogContentText>
            Você gostaria de reverter o status do item:
          </DialogContentText>

          <DialogContentText>
            {data?.conta?.user?.nome ||
              data?.contrato_aluguel?.locatario?.user?.nome}
            <br />
            {data?.conta?.user?.documento ||
              data?.contrato_aluguel?.locatario?.user?.documento}
            <br />
            Status: {data?.status}
            <br />
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button color="primary" type="submit">
            Reverter
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const BlockUnblockCardModal = ({
  show = false,
  setShow = () => false,
  data = {},
  getData = () => null,
}) => {
  const token = useAuth();
  const [loading, setLoading] = useState("");

  const handleClose = () => {
    setShow(false);
  };

  const actionName = data?.is_blocked ? "desbloquear" : "bloquear";

  const handleResetStatus = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (data?.is_blocked) {
        await postUnblockCard(token, data?.id);
      } else await postBlockCard(token, data?.id);

      await getData(token);
    } catch (err) {
      console.log(err);
      toast.error(
        `Ocorreu um erro, não possivel ${actionName} o cartão. Tente novamente.`
      );
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <LoadingScreen isLoading={loading} />
      <DialogTitle id="form-dialog-title">{`Você gostaria de ${actionName} o cartão?`}</DialogTitle>
      <form onSubmit={handleResetStatus}>
        <DialogContent style={{ overflow: "hidden" }}>
          <DialogContentText>
            {data?.user?.nome}
            <br />
            {data?.user?.documento}
            <br />
            Status: {data?.status}
            <br />
            {data?.external_msk ? (
              <>
                {data?.external_msk}
                <br />
              </>
            ) : null}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button color="primary" type="submit">
            {actionName}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
