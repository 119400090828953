import "../../fonts/Montserrat-SemiBold.otf";

import { Box, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import {
  filters_historico_pix,
  filters_historico_ted,
  filters_historico_transacoes,
  filters_historico_transferencia,
} from "../../constants/localStorageStrings";

import { useSelector } from "react-redux";
import { useParams } from "react-router";
import AccountCollections from "../../components/AccountCollections/AccountCollections";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
  },
  headerContainer: {
    /* padding: '80px 400px ', */
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "25px",
  },
  pageTitle: {
    color: "#c6930a",
    fontFamily: "Montserrat-SemiBold",
  },
  subTitleContainer: {
    margin: "15px 15px",
    display: "flex",
    justifyContent: "space-between",
  },
  contentContainer: {
    marginTop: "20px",
  },
}));

const GerenciarListaDeContas = () => {
  const classes = useStyles();
  const conta = useSelector((state) => state.conta);
  const { subsection } = useParams();
  //const isBanking = conta?.is_default_app_account;
  const isAdquirencia = conta?.solicitado_adquirencia;
  const isEstabelecimento = conta?.is_estabelecimento;
  const isGestao = conta?.is_gestao_concorrencia;
  const isBanking = ( isEstabelecimento == false && isGestao == false ) ? true : false; 

  useEffect(() => {
    localStorage.removeItem(filters_historico_transacoes);
    localStorage.removeItem(filters_historico_transferencia);
    localStorage.removeItem(filters_historico_ted);
    localStorage.removeItem(filters_historico_pix);
  }, []);

  return (
    <Box className={classes.root}>
      <AccountCollections
        title="Todos"
        ted={isBanking}
        pix={isBanking}
        pagamentoConta={isBanking}
        chavespix={isBanking}
        cartao={isBanking}
        boleto={isBanking}
        carne={isBanking}
        assinaturas={isBanking}
        link={isBanking}
        extrato={isBanking}
        historicoTransacoes={isBanking}
        historicoTransferencia={isBanking}
        pagadores={isBanking}
        terminais={isBanking}
        recarga={isBanking}
        folhaPagamento={isBanking && subsection === "lista-conta-juridica"}
        exportacoesSolicitadas={isBanking}
        tarifas={isBanking}
        extrato_adquirencia={isAdquirencia}
        extrato_beneficios={isEstabelecimento}
        beneficiarios={isGestao}
        cartoesBeneficiarios={isGestao}
        voucherBeneficiarios={isGestao}
        pagamentoCartaoPrivado={isGestao}
        pagamentoEstabelecimento={isGestao}
        pagamentoContaVoucher={isGestao}
        autorizaPagamentoEstabelecimento={isGestao}
        autorizaPagamentoContaVoucher={isGestao}
        listaBeneficios={isGestao}
        listaContratoAluguel={isGestao}
        pagamentoContratoAluguel={isGestao}
        autorizaPagamentoContratoAluguel={isGestao}
      />
    </Box>
  );
};

export default GerenciarListaDeContas;
