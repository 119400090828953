import {
  faArchive,
  faBarcode,
  faBuilding,
  faCheck,
  faCopy,
  faCreditCard,
  faDesktop,
  faDollarSign,
  faFileInvoiceDollar,
  faFileSignature,
  faHistory,
  faLink,
  faList,
  faListAlt,
  faMobile,
  faMobileAlt,
  faMoneyBill,
  faTags,
  faTicketAlt,
  faUndo,
  faUsers,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { Box, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadPermissao, postAuthMeAction } from "../../actions/actions";
import useAuth from "../../hooks/useAuth";
import AccountCollectionItem from "./AccountCollectionItem/AccountCollectionItem";

const useStyles = makeStyles((theme) => ({
  accountCollectionContainer: {
    width: "60%",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    color: theme.palette.primary.main,
    [theme.breakpoints.down(850)]: {
      width: "100%",
    },
  },
}));

const AccountCollections = ({
  title,
  ted,
  pix,
  pagamentoConta,
  chavespix,
  cartao,
  boleto,
  carne,
  assinaturas,
  link,
  extrato,
  historicoTransacoes,
  historicoTransferencia,
  pagadores,
  terminais,
  recarga,
  folhaPagamento,
  exportacoesSolicitadas,
  tarifas,
  extrato_adquirencia,
  extrato_beneficios,
  beneficiarios,
  cartoesBeneficiarios,
  voucherBeneficiarios,
  pagamentoCartaoPrivado,
  pagamentoEstabelecimento,
  pagamentoContaVoucher,
  autorizaPagamentoEstabelecimento,
  autorizaPagamentoContaVoucher,
  listaBeneficios,
  listaContratoAluguel,
  pagamentoContratoAluguel,
  autorizaPagamentoContratoAluguel,
}) => {
  const [permissoes, setPermissoes] = useState([]);
  const token = useAuth();
  const me = useSelector((state) => state.me);
  const userPermissao = useSelector((state) => state.userPermissao);
  const dispatch = useDispatch();
  const classes = useStyles();

  /* const userPermissao = useSelector((state) => state.userPermissao);
	const [permissoes, setPermissoes] = useState([]);

	useEffect(() => {
		const { permissao } = userPermissao;
		setPermissoes(permissao.map((item) => item.tipo));
	}, [userPermissao]);

	useEffect(() => {
		return () => {
			setPermissoes([]);
		};
	}, []); */
  useEffect(() => {
    dispatch(postAuthMeAction(token));
  }, []);

  useEffect(() => {
    const { permissao } = userPermissao;
    setPermissoes(permissao.map((item) => item.tipo));
  }, [userPermissao]);

  useEffect(() => {
    if (me.id !== undefined) {
      dispatch(loadPermissao(token, me.id));
    }
  }, [me.id]);

  return (
    <Box className={classes.accountCollectionContainer}>
      <Typography variant="h6">{title}</Typography>

      <Box display="flex">
        {cartao ? (
          <AccountCollectionItem
            link=/* {permissoes.includes('Cobranca - Cartao') ?  */ "cobrancas-credito" /*  : null} */
            text="Máquina Virtual"
            icon={faCreditCard}
          />
        ) : null}

        {boleto ? (
          <AccountCollectionItem link="boleto" text="Boleto" icon={faBarcode} />
        ) : null}

        {carne ? (
          <AccountCollectionItem
            link=/* {permissoes.includes('Cobranca - Carne') ?  */ "carne" /*  : null} */
            text="Carnê"
            icon={faCopy}
          />
        ) : null}
      </Box>

      <Box display="flex">
        {link ? (
          <AccountCollectionItem
            link=/* {permissoes.includes('Cobranca - Link Pagamento') ?  */ "link-pagamento" /*  : null} */
            text="Link de Pagamento"
            icon={faLink}
          />
        ) : null}

        {pagadores ? (
          <AccountCollectionItem
            link="pagadores"
            text="Pagadores"
            icon={faUsers}
          />
        ) : null}

        {extrato ? (
          <AccountCollectionItem
            link={
              permissoes.includes("Atendimento - Consulta de extrato") ||
              permissoes.includes("Administrador - Acesso total")
                ? "extrato"
                : null
            }
            text="Extrato"
            icon={faDollarSign}
          />
        ) : null}
      </Box>

      <Box display="flex">
        {assinaturas ? (
          <AccountCollectionItem
            link=/* {permissoes.includes('Cobranca - Assinatura') ?  */ "assinaturas" /*  : null} */
            text="Cobrança Recorrente"
            icon={faUndo}
          />
        ) : null}
        {historicoTransacoes ? (
          <AccountCollectionItem
            link={
              permissoes.includes(
                "Operações - Transações e histórico de transações não concluídas"
              ) || permissoes.includes("Administrador - Acesso total")
                ? "historico-de-transacoes"
                : null
            }
            text="Histórico de Transações"
            icon={faDesktop}
          />
        ) : null}

        {historicoTransferencia ? (
          <AccountCollectionItem
            link="historico-transferencia"
            text="Histórico de Transferência"
            icon={faHistory}
          />
        ) : null}
      </Box>

      <Box display="flex">
        {ted ? (
          <AccountCollectionItem
            link="transferencia-ted"
            text="Transferência TED"
            icon={faUsers}
          />
        ) : null}
        {pix ? (
          <AccountCollectionItem
            link="transacoes-pix"
            text="Transações PIX"
            icon={faWallet}
          />
        ) : null}
        {chavespix ? (
          <AccountCollectionItem
            link="chaves-pix"
            text="Chaves PIX"
            icon={faTags}
          />
        ) : null}
      </Box>

      <Box display="flex">
        {pagamentoConta ? (
          <AccountCollectionItem
            link="pagamento-conta"
            text="Pagamento Conta"
            icon={faMoneyBill}
          />
        ) : null}

        {/* {giftCard ? (
						<AccountCollectionItem
						link='gift-cards'
						text="Gift Card"
						icon={faGift}
					/>
					): null} */}
        {recarga ? (
          <AccountCollectionItem
            link="recarga-celular"
            text="Recarga"
            icon={faMobileAlt}
          />
        ) : null}
        {extrato_adquirencia ? (
          <AccountCollectionItem
            link={"extrato-adquirencia"}
            text="Extrato Adquirência"
            icon={faList}
          />
        ) : null}
      </Box>

      <Box display="flex">
        {terminais ? (
          <AccountCollectionItem
            link="terminais-pos"
            text="Terminais - POS"
            icon={faMobile}
          />
        ) : null}
        {exportacoesSolicitadas ? (
          <AccountCollectionItem
            link={"exportacoes-solicitadas"}
            text="Exportações Solicitadas"
            icon={faArchive}
          />
        ) : null}
        {tarifas ? (
          <AccountCollectionItem
            link={"tarifas"}
            text="Tarifas"
            icon={faMoneyBill}
          />
        ) : null}
      </Box>

      <Box display="flex">
        {folhaPagamento ? (
          <AccountCollectionItem
            link="folha-de-pagamento"
            text="Folha de Pagamento"
            icon={faListAlt}
          />
        ) : null}
        {extrato_beneficios ? (
          <AccountCollectionItem
            link={"extrato-adquirencia"}
            text="Extrato Benefícios"
            icon={faList}
          />
        ) : null}
      </Box>

      <Box display="flex">
        {beneficiarios ? (
          <AccountCollectionItem
            link={"lista-beneficiarios"}
            text="Beneficiários"
            icon={faUsers}
          />
        ) : null}
        {listaBeneficios ? (
          <AccountCollectionItem
            link={"lista-beneficios"}
            text="Lista de benefícios"
            icon={faList}
          />
        ) : null}
      </Box>

      <Box display="flex">
        {cartoesBeneficiarios ? (
          <AccountCollectionItem
            link={"lista-beneficiarios-cartao"}
            text="Cartões dos Beneficiários"
            icon={faWallet}
          />
        ) : null}
        {pagamentoCartaoPrivado ? (
          <AccountCollectionItem
            link={"pagamento-beneficiarios-cartao"}
            text="Pagamento Cartão Privado"
            icon={faCreditCard}
          />
        ) : null}
      </Box>

      <Box display="flex">
        {voucherBeneficiarios ? (
          <AccountCollectionItem
            link={"lista-beneficiarios-voucher"}
            text="Vouchers dos Beneficiários"
            icon={faBuilding}
          />
        ) : null}
        {pagamentoContaVoucher ? (
          <AccountCollectionItem
            link={"pagamento-beneficiarios-voucher"}
            text="Pagamento Conta Voucher"
            icon={faTicketAlt}
          />
        ) : null}
        {autorizaPagamentoContaVoucher ? (
          <AccountCollectionItem
            link={"autorizar-pagamento-beneficiarios-voucher"}
            text="Autorizar Pagamento Conta Voucher"
            icon={faCheck}
          />
        ) : null}
      </Box>

      <Box display="flex">
        {listaContratoAluguel ? (
          <AccountCollectionItem
            link={"lista-contrato-aluguel"}
            text="Contrato de Aluguel"
            icon={faFileSignature}
          />
        ) : null}
        {pagamentoContratoAluguel ? (
          <AccountCollectionItem
            link={"pagamento-contrato-aluguel"}
            text="Pagamento Contrato de Aluguel"
            icon={faFileInvoiceDollar}
          />
        ) : null}
        {autorizaPagamentoContratoAluguel ? (
          <AccountCollectionItem
            link={"autorizar-pagamento-contrato-aluguel"}
            text="Autorizar Pagamento Contrato de Aluguel"
            icon={faCheck}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default AccountCollections;
